<template>
  <div class="MapBg">
    <img src="../assets/img/leidaBg.png" class="leidaBg Rotation" />
    <img src="../assets/img/earthBg.png" class="earthBg earthRotation" />
  </div>
</template>
<script>
export default {
  name: 'chargeMapOfHubei',

  data () {
    return {
    }
  },
  created () {
  },
  // 调用
  mounted () {
  },
  methods: {

  }

}
</script>
<style lang="less">
.MapBg {
  width: 100%;
  height: 100%;
  .leidaBg {
    filter: alpha(opacity=15);
    opacity: 0.18;
    position: absolute;
    width: 40vw;
    height: 40vw;
    top: 11%;
    left: 28.5vw;
  }
  .earthBg {
    filter: alpha(opacity=15);
    opacity: 0.18;
    position: absolute;
    width: 40vw;
    height: 40vw;
    top: 11%;
    left: 28.5vw;
  }
  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .Rotation {
    -webkit-transform: rotate(360deg);
    animation: rotation 30s linear infinite;
    -moz-animation: rotation 30s linear infinite;
    -webkit-animation: rotation 30s linear infinite;
    -o-animation: rotation 30s linear infinite;
  }
  @-webkit-keyframes earthrotation {
    from {
      -webkit-transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }

  .earthRotation {
    -webkit-transform: rotate(0deg);
    animation: earthrotation 30s linear infinite;
    -moz-animation: earthrotation 30s linear infinite;
    -webkit-animation: earthrotation 30s linear infinite;
    -o-animation: earthrotation 30s linear infinite;
  }
}
</style>
