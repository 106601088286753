<template>
  <div class="sixMenuClass">
    <div id="deviceId" style="width: 50vw;height: 48vw;margin:-22% 21.5vw"></div>
    <div class="transitionDivLeft">
      <div class="transitionName" v-show="initData.transitionLeftShow">【{{initData.clickedDevName}}】- 共{{initData.projectNum}}个项目在线</div>
      <div class="transitionContent">
        <transition name="el-zoom-in-center">
          <div v-show="initData.transitionLeftShow">
            <el-button class="transition-box" v-for="item in initData.leftDevProArr" :key="item" @click="clickPro(item)" :disabled="item.deployFlag === 1">
              {{item.projName}}{{item.projDetail}}
            </el-button>
          </div>
        </transition>
      </div>
    </div>
    <div class="transitionDivRight">
      <div class="transitionName" v-show="initData.transitionRightShow">【{{initData.clickedDevName}}】- 共{{initData.projectNum}}个项目在线</div>
      <div class="transitionContent">
        <transition name="el-zoom-in-center">
          <div v-show="initData.transitionRightShow">
            <el-button class="transition-box" v-for="item in initData.rightDevProArr" :key="item" @click="clickPro(item)" :disabled="item.deployFlag === 1">
              {{item.projName}}{{item.projDetail}}
            </el-button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import { useRouter } from "vue-router";
import { ElLoading } from 'element-plus';

export default {
  setup () {
    const newApi = inject('newApi');
    const router = useRouter();
    const initData = reactive({
      loading: "",
      transitionLeftShow: false,
      transitionRightShow: false,
      deviceProjectArr: [],
      clickedDevProArr: [],
      clickedDevName: "",
      projectNum: "",
      leftDevProArr: [],
      rightDevProArr: [],
      deviceNameArr: [],
      projDetailData: "",
      clickedDevProdCode: ""
    });
    onMounted(() => {
      openFullScreen();
      new Promise(resolve => {
        var allCode = "all";
        newApi.initProjects({}, allCode).then(res => {
          if (res.code == 500) {
            ElMessage.info({
              message: res.msg
            });
          } else {
            ElMessage.success({
              message: "数据连接成功！"
            });
            initData.loading.close();
            initData.deviceProjectArr = res;
            let codeData = [];
            for (var i in initData.deviceProjectArr) {
              codeData.push(i)
            }
            getprojectDetail(codeData);
          }
        })
        resolve();
      })
    });
    const getprojectDetail = (codeData) => {
      newApi.authProductQuery({}).then(res => {
        let data = res;
        let projDetailData = [];
        codeData.forEach(ele => {
          data.forEach((ala) => {
            if (ala.prodCode == ele) {
              projDetailData.push(ala);
            }
          })
        })
        dealProjData(projDetailData);
      })
    }
    const dealProjData = (data) => {
      new Promise(function (resolve) {
        data.forEach((ele, i) => {
          try {
            ele.deviceImgUrl = require("../assets/img/device/" + ele.prodCode + ".png"); //项目图片与项目名称对应起来
          } catch (e) {
            ele.deviceImgUrl = require("../assets/img/device/" + "nopic" + ".png"); //没有设备图片的时候，载入备用图片
          }
          ele.code = ele.prodCode;
          ele.name = ele.prodName;
          ele.times = 2;
          if (i % 2 == 0) {
            ele.type = 2;
          } else {
            ele.type = 1;
          }
        })
        resolve();
      }).then(function () {
        initData.deviceNameArr = data;
        initEcharts();
        return false;
      }).catch(function (err) {
        console.log(err);
      });
    }
    const openFullScreen = () => {
      initData.loading = ElLoading.service({
        lock: true,
        text: '数据加载中...',
        background: 'rgba(0, 0, 0, 0.5)',
      })
    }
    const chunk = (array, subGroupLength) => {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      if (newArray.length == 3) {
        newArray = [newArray[0], newArray[1].concat(newArray[2])];
        return newArray;
      } else {
        return newArray;
      }
    }
    async function showProduct (item) {

      let projCode = item.projCode;
      let projPath = item.projPath;
      let projName = item.projName;
      let projDetail = item.projDetail;

      await window.localStorage.removeItem("checkedString");
      await window.localStorage.setItem("screenProjCode", projCode);
      await window.localStorage.setItem("screenProjClient", projName + projDetail);
      await window.localStorage.setItem("screenProjVersion", item.version);
      
      if (projPath) {
        //外链
        if (projPath.indexOf("http") > -1) {
          window.open(projPath, '_blank');
        } else {
          window.open(projPath + "/#/main?projectCode=" + projCode, '_blank');
        }
      } else {
        //window.open("/melves/#/main?projectCode=" + projCode, '_blank');
        window.open("/" + initData.clickedDevProdCode + "/#/main?projectCode=" + projCode, '_blank');
      }

    }
    const clickPro = (item) => {
      showProduct(item);
    }
    const clickGetCode = (echartsData) => {
      initData.clickedDevName = [];
      initData.clickedDevProdCode = [];
      initData.clickedDevName = echartsData.data.name;
      initData.clickedDevProdCode = echartsData.data.prodCode;
      var clickedCode = echartsData.data.code;
      initData.clickedDevProArr = initData.deviceProjectArr[clickedCode];
      initData.leftDevProArr = [];
      initData.rightDevProArr = [];
      if (initData.clickedDevProArr == undefined) {
        return false;
        // initData.clickedDevProArr = [];
        // var projectObj = {};
        // projectObj.projName = "测试界面";
        // projectObj.projDetail = "暂无数据";
        // initData.leftDevProArr.push(projectObj);
        // initData.projectNum = initData.clickedDevProArr.length;
        // setTimeout(function () {
        //   initData.transitionLeftShow = true;
        // }, 500)
      } else if (initData.clickedDevProArr.length > 13) {
        initData.projectNum = initData.clickedDevProArr.length;
        initData.leftDevProArr = chunk(initData.clickedDevProArr, Math.round(initData.projectNum / 2))[0];
        initData.rightDevProArr = chunk(initData.clickedDevProArr, Math.round(initData.projectNum / 2))[1];
        setTimeout(function () {
          initData.transitionLeftShow = true;
          initData.transitionRightShow = true;
        }, 500)
      } else {
        initData.projectNum = initData.clickedDevProArr.length;
        initData.leftDevProArr = initData.clickedDevProArr;
        setTimeout(function () {
          initData.transitionLeftShow = true;
        }, 500)
      }
    }

    const initEcharts = () => {
      let echarts = require('echarts');
      let myChart = echarts.init(document.getElementById('deviceId'));
      myChart.on('click', function (params) {
        if (params.data.code == undefined) {
          return false;
        } else {
          initData.transitionLeftShow = false;
          initData.transitionRightShow = false;
          clickGetCode(params);
        }
      })
      let erArr = initData.deviceNameArr;
      let sumTimes = 0;
      sumTimes = erArr.reduce(function (prev, next) {
        return prev + next.times;
      }, 0);
      let color = ["#ffffff", "rgba(28, 211, 230, 0.6)", "rgba(91, 84, 255, 0.5)", "#67f95f"];
      let oneArr = [
        {
          name: "智慧数据",
          times: sumTimes,
          symbolSize: 198,
          color: "#25d7f0",
          itemStyle: {
            color: "#25d7f0",
          },
        },
      ];

      let allArr = [...oneArr, ...erArr],
        dataArr = [];
      // 点
      allArr.forEach((el) => {
        var imgUrl = "image://" + el.deviceImgUrl;
        var size = 120;
        var show = true;
        if (el.symbol) {
          imgUrl = el.symbol;
          size = el.symbolSize;
          show = false;
        }
        el.symbolSize = size;
        el.symbol = imgUrl;
        el.itemStyle = {
          opacity: 1
        };
        el.label = {
          clickable: true,
          normal: {
            show: show,
            position: "bottom",
            textStyle: {
              fontSize: 15,
              fontWeight: "bold",
              color: "#FFFFFF",
            },
          },
        };
      });

      // 圆形分区
      function group (arr, r) {
        const newArray = [];
        const { length: arLen } = arr;
        arr.forEach((e, ind) => {
          // 按角度均匀分布
          const ang = 90 - (360 / arLen).toFixed(2) * (ind + 1);
          const x = Math.cos((ang * Math.PI) / 180).toFixed(2) * r;
          const y = Math.sin((ang * Math.PI) / 180).toFixed(2) * r;
          const x1 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 5);
          const y1 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 5);
          const x0 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 30);
          const y0 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 30);
          e.value = [x.toFixed(2), y.toFixed(2)];
          e.twoPoint = [
            [x1.toFixed(2), y1.toFixed(2)],
            [x0.toFixed(2), y0.toFixed(2)],
          ];
          newArray.push(e);
        });
        return newArray;
      }

      // 线配置
      function linesConfig (arr) {
        const [dataArr] = [[]];
        arr.forEach((el) => {
          function getFormatItem (start, end) {
            let item = [
              { coord: el.twoPoint[start] }, // 起点
              {
                coord: el.twoPoint[end],
                lineStyle: {
                  color: color[el.type],
                  curveness: el.type === 3 ? 0.1 : 0,
                },
                effect: {
                  color: color[el.type],
                  symbolSize: [8, 15],
                },
              }, // 终点
            ];
            return item;
          }
          switch (el.type) {
            case 0:
              break;
            case 1:
              dataArr.push(getFormatItem(0, 1));
              break;
            case 2:
              dataArr.push(getFormatItem(1, 0));
              break;
            case 3:
              dataArr.push(getFormatItem(0, 1));
              dataArr.push(getFormatItem(1, 0));
              break;
          }
        });
        return dataArr;
      }

      // 点分布
      oneArr = group(oneArr, 0);
      erArr = group(erArr, 40);
      allArr = [...oneArr, ...erArr];
      // 线坐标和配置
      dataArr = linesConfig(allArr);
      function generateData (totalNum, bigvalue, smallvalue, color, width) {
        let dataArr = [];
        for (var i = 0; i < totalNum; i++) {
          if (i % 2 === 0) {
            dataArr.push({
              name: (i + 1).toString(),
              value: bigvalue,
              itemStyle: {
                color: color,
                borderWidth: width,
                borderRadius: 20,
              },
            });
          } else {
            dataArr.push({
              name: (i + 1).toString(),
              value: smallvalue,
              itemStyle: {
                color: "rgba(0,0,0,0)",
                borderWidth: 0,
                borderRadius: 20,
              },
            });
          }
        }
        return dataArr;
      }
      let threeData = generateData(6, 30, 10, "rgba(28, 211, 230, 1)", 5);
      let fourData = generateData(12, 80, 10, "rgba(91, 84, 255, 0.7)", 1);
      let fiveData = generateData(200, 80, 10, "rgba(61, 104, 205, 0.4)", 1);
      let radius1 = ["45%", "43%"];
      let radius2 = ["30%", "28%"];
      let radius3 = ["45%", "31%"];
      let height = document.body.clientHeight;
      if (height < 1000) {
        radius1 = ["45%", "44%"];
        radius2 = ["32%", "31%"];
        radius3 = ["45%", "34%"];
      }
      function getOption (startAngle, startAngle2) {
        let option = {
          tooltip: {
            show: true,
            formatter: function (param) {
              return <div style="color:#fff;" onclick="alert('12')">123</div>;
            },
            textStyle: {
              fontSize: 20,
            },
            backgroundColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#ffffff",
            },
            borderWidth: 0,
          },
          title: {
            text: "智慧云数据平台",
            left: "center",
            top: "center",
            textStyle: {
              color: "#fff",
              fontSize: 30,
            },
          },
          xAxis: {
            show: false,
            type: "value",
            max: 50,
            min: -51,
          },
          grid: {
            top: '2%',
            bottom: 10,
            left: 10,
            right: 10,
          },
          yAxis: {
            show: false,
            type: "value",
            max: 50,
            min: -50,
          },
          series: [
            {
              name: "节点",
              type: "graph",
              silent: false,
              hoverAnimation: false, // 鼠标悬浮高亮
              cursor: "default",
              coordinateSystem: "cartesian2d",
              z: 2,
              itemStyle: {
                shadowColor: "none",
              },
              emphasis: {
                scale: false,
              },
              data: allArr,
            },
            {
              name: "线图",
              type: "lines",
              hoverAnimation: false,
              silent: false,
              cursor: "default",
              coordinateSystem: "cartesian2d",
              polyline: false, // 多线段
              z: 1,
              lineStyle: {
                width: 2,
                type: "dashed",
                curveness: 0,
              },
              effect: {
                show: true,
                period: 16, //箭头指向速度，值越小速度越快
                trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: "arrow", //箭头图标
                symbolSize: 19,
              },
              emphasis: {
                lineStyle: {
                  type: "dashed",
                },
              },
              data: dataArr,
              tooltip: {
                show: false,
              },
            },
            {
              type: "pie",
              name: "旋转圆",
              zlevel: 20,
              silent: true,
              radius: radius1,
              hoverAnimation: false,
              startAngle: startAngle,
              data: threeData,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
            {
              type: "pie",
              name: "旋转圆",
              zlevel: 19,
              silent: true,
              radius: radius2,
              hoverAnimation: false,
              startAngle: startAngle2,
              data: fourData,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
            {
              type: "pie",
              name: "旋转圆",
              zlevel: 19,
              silent: true,
              radius: radius3,
              hoverAnimation: false,
              startAngle: startAngle2,
              data: fiveData,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        };
        return option;
      }
      getOption();
      let startAngle = 50; // 初始旋转角度
      let startAngle2 = 50; // 初始旋转角度

      function draw () {
        startAngle = startAngle - 5;
        startAngle2 = startAngle2 + 5;
        let option = getOption(startAngle, startAngle2);
        myChart.setOption(option, true);
      }
      draw();
      // setInterval(draw, 1000);
    }
    return {
      initData,
      clickPro
    }
  }
}
</script>
<style lang="less">
.sixMenuClass {
  width: 100%;
  height: 100%;
}
.transitionDivLeft {
  position: absolute;
  left: 0px;
  top: 3rem;
}
.transitionDivRight {
  position: absolute;
  left: 74vw;
  top: 3rem;
}
.transitionContent {
  width: 22vw;
  height: 76vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 0;
  margin: 1rem 0;
  .el-button {
    background: url("../assets/img/5-btnBg.png");
    background-size: 100% 100%;
    border: none;
    margin-left: 1rem;
  }
  .is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    width: 19vw !important;
    height: 3.5rem !important;
    margin-top: 0.3rem !important;
    color: #d2d2d2 !important;
    background: url("../assets/img/5-btnBgGrey.png");
    background-size: 100% 100%;
    border: none;
    margin-left: 1rem;
  }
}
.transitionName {
  color: rgba(27, 248, 250, 1);
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.transition-box {
  width: 19vw;
  height: 3.5rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
  margin-top: 0.3rem;
  margin-left: 0;
}
.transition-box:hover {
  background-image: url("../assets/img/6-btnBgActive.png");
  background-size: 100% 100%;
  width: 21.5vw;
  height: 3.8rem;
  color: #020926;
  margin-top: 0rem;
}
</style>
