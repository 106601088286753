<template>
  <div class="indexMain sixMenuMain">
    <div class="indexBg">
      <div class="mainTitleDiv">
        <div class="titleFont">
          智慧云数据平台
        </div>
      </div>
      <div class="mapBgDiv">
        <mapBg></mapBg>
      </div>
      <sixMenu class="sixMenu"></sixMenu>
      <!-- <div class="logoFont">
        北京新科汇智科技发展有限公司
      </div> -->
      <div class="portalOpenMenu">
        <el-button type="primary" size="mini" @click="outLoginClick()" class="goHomeBtn">
          退出登录
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import mapBg from '@/components/mapBg.vue';
import sixMenu from '@/components/sixMenu.vue';
import { outLogin } from 'sudp-common/public/outLogin';

export default {
  components: {
    mapBg,
    sixMenu
  },
  setup () {
    const outLoginClick = () => {
      outLogin();
    }

    return {
      outLoginClick
    }
  }
}
</script>
<style>
.divMargin {
  margin: 1vh 0.5vw;
}
.divMarginBig {
  margin: 1vh 1vw;
}
.divMarginBiger {
  margin: 1vh 1.5vw;
}
.sixMenuMain .goHomeBtn {
  top: 1%;
  right: 0;
  position: absolute;
}
.sixMenuMain .deviceBtn {
  top: 5%;
  right: 0;
  position: absolute;
}
.sixMenuMain .el-button--primary {
  height: 2.4rem;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(48, 248, 250, 0.9) !important;
  border: 0.1rem solid rgba(48, 248, 250, 0.8);
  border-left: 0.3rem solid rgba(48, 248, 250, 0.8);
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
  -moz-text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
  -webkit-text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
}
.sixMenuMain .el-button--primary:hover {
  width: 8rem;
  color: #000b38 !important;
  background-color: rgba(48, 248, 250, 0.9) !important;
  box-shadow: 0 0 8px #ddd;
  -moz-box-shadow: 0 0 8px #ddd;
  -webkit-box-shadow: 0 0 8px #ddd;
}
.mapBgDiv {
  width: 1%;
  height: 1%;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(14, 40, 94, 1);
  background: rgba(65, 158, 255, 0.8);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(14, 40, 94, 1);
  border-radius: 10px;
  background: rgba(14, 40, 94, 1);
}
.sixMenu {
  width: 20vw;
  height: 20vw;
  position: absolute;
  top: 10%;
  left: 2%;
}
.mainTitleDiv {
  width: 100%;
  height: 6vh;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(102, 250, 251, 0.2);
}
.logoImg {
  width: 2.8vh;
  height: 2.8vh;
  float: left;
  margin: 1.6vh 0 0 1vw;
}
.logoFont {
  line-height: 4vh;
  position: absolute;
  bottom: -8px;
  right: 46.3%;
  font-size: 1vh;
  color: #bbb7b7;
}

.goScreenBtn {
  line-height: 6vh;
  position: absolute;
  right: 1%;
  font-size: 1vh;
  color: #ffffff;
}
.titleFont {
  width: 60%;
  height: 6vh;
  float: left;
  line-height: 6vh;
  text-align: left;
  padding: 0 0 0 1vw;
  font-size: 2.2vh;
  font-weight: bold;
  color: transparent;
  font-family: "微软雅黑";
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(rgba(48, 248, 250, 1)),
    to(rgba(255, 255, 255, 1))
  );
  text-shadow: rgba(255, 255, 255, 0.4) 0 3px 4px,
    rgba(255, 255, 255, 0.2) 1px 3px 3px;
  -webkit-background-clip: text;
}
</style>
